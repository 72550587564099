import { Geolocation } from '@capacitor/geolocation'
import i18n from '@/i18n.js'
import util from '@/util'

// on web no api to check is implemented so set true
var permission = util.getPlatform() === 'web'
function _getPermission(check) {
  return check && permission
    ? Promise.resolve()
    : Geolocation[check ? 'checkPermissions' : 'requestPermissions']()
        .then((r) => {
          let okStates = ['granted', 'prompt', 'prompt-with-rationale']
          if (
            okStates.includes(r.location) ||
            okStates.includes(r.coarseLocation)
          ) {
            permission = true
            return Promise.resolve()
          } else {
            return check
              ? _getPermission(false)
              : Promise.reject({ message: r })
          }
        })
        .catch((e) => {
          console.log('_getPersmission', e)
          permission = false
          return check ? _getPermission(false) : Promise.reject(e)
        })
}

async function getLocation() {
  return _getPermission(true)
    .then(async () => {
      const position = await Geolocation.getCurrentPosition({
        enableHighAccuracy: true,
        timeout: 10000
      })
      return Promise.resolve({
        lat: position.coords.latitude,
        lng: position.coords.longitude
      })
    })
    .catch((e) => Promise.reject(i18n.global.t('geopermission')))
}

var watchId
function watchPosition() {
  watchId = Geolocation.watchPosition({}, (position, e) => {
    console.log(position, e)
  })
}
function clearWatch() {
  Geolocation.clearWatch({ id: watchId })
}

const MAX_DISTANCE = 0.15

function findNearestPlace(location, places, ignoreMax) {
  let distances = places.map((place) =>
    place.lat && place.lng
      ? Math.sqrt(
          Math.pow(place.lat - location.lat, 2) +
            Math.pow(place.lng - location.lng, 2)
        )
      : Infinity
  )
  let nearest = Math.min(...distances)
  return ignoreMax || nearest < MAX_DISTANCE
    ? places[distances.indexOf(nearest)]
    : null
}

export default {
  getLocation,
  watchPosition,
  clearWatch,
  findNearestPlace
}
