<template>
  <div class="biemap" :class="{ 'biemap-museum': museum }">
    <div id="map"></div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

export default {
  name: 'BieMap',
  data() {
    return {
      map: 0,
      mapMarker: null,
      locationRadius: null,
      mediaMarker: []
    }
  },
  computed: Object.assign(
    mapState({
      ...mapGetters(['getPlaceById']),
      museum: (state) => state.museum,
      position: (state) => state.position,
      location: (state) => state.location,
      filterDistance: (state) => state.filterDistance
    }),
    {
      forPhoto() {
        return this.$route.name === 'photo'
      },
      onMuseum() {
        return this.museum || this.$route.name === 'museum'
      }
    }
  ),
  watch: {
    position() {
      this.setMarker()
    },
    location() {
      this.setMarker()
    },
    filterDistance(filterDistance) {
      this.locationRadius?.setRadius(filterDistance)
    }
  },
  methods: {
    setMarker() {
      if (this.map) {
        const latlng = this.onMuseum ? this.location?.latlng : this.position
        if (latlng) {
          const icon = L.divIcon({
            className: this.onMuseum ? 'icon-location' : 'icon-position',
            iconSize: [26, 26],
            iconAnchor: [13, 21]
          })
          if (!this.mapMarker) {
            this.mapMarker = L.marker(latlng, {
              zIndexOffset: 1010,
              icon: icon
            })
              .on('click', (e) => {
                this.$store.dispatch('setLatLng', latlng)
                if (this.forPhoto) {
                  this.$store.commit('sideContent', false)
                }
              })
              .addTo(this.map)
            if (!this.onMuseum) {
              this.mapMarker.bindTooltip(this.$t('map.home'))
            } else {
              this.locationRadius = L.circle(latlng, {
                zIndexOffset: 1000,
                weight: 0.5,
                color: '#000000',
                fillOpacity: 0.1,
                radius: this.filterDistance
              }).addTo(this.map)
            }
          } else {
            this.mapMarker.setIcon(icon)
            this.mapMarker.setLatLng(latlng)
            if (!this.forPhoto) {
              this.locationRadius?.setLatLng(latlng)
            }
          }
        } else {
          this.mapMarker && this.map.removeLayer(this.mapMarker)
          this.mapMarker = null
          this.locationRadius && this.map.removeLayer(this.locationRadius)
          this.locationRadius = null
        }
      }
    },
    // clearMediaMarker() {
    //   this.mediaMarker.forEach((marker) => this.map.removeLayer(marker))
    // },
    // setMediaMarker() {
    //   if (!this.museum) {
    //     this.userUploads?.media
    //       .concat(this.defaultUploads?.media)
    //       .forEach((medium) => {
    //         if (medium.latlng) {
    //           const placeTitle =
    //             this.getPlaceById(medium.placeId)?.title ||
    //             util.shortLatLng(medium).join(', ')
    //           const mediaMarker = L.marker(medium.latlng, {
    //             icon: L.divIcon({
    //               className: 'map-medium',
    //               iconSize: [30, 30],
    //               html: `<img src="${this.mediaServer}${medium.thumbURL}" alt="">`,
    //               opacity: 0.75,
    //               riseOnHover: true
    //             })
    //           }).bindTooltip([medium.title, '@', placeTitle].join(' '))
    //           mediaMarker.on('click', (e) => {
    //             this.$store.dispatch('setLatLng', e.latlng)
    //             this.$store.commit('sideContent', false)
    //           })
    //           mediaMarker.addTo(this.map)
    //           this.mediaMarker.push(mediaMarker)
    //         }
    //       })
    //   }
    // },
    initMap() {
      const LAYERS = {
        basis: {
          wms: 'https://www.bielefeld01.de/md/WMS/bielefeld_karte_farbe/02?',
          layers: 'map'
        },
        bezirke: {
          wms: 'https://www.bielefeld01.de/md/WMS/statistische_gebietsgliederung/02?',
          layers: 'stadtbezirke_pl'
        }
      }
      const ZOOM = this.museum
        ? 11
        : window.matchMedia('(min-width: 1024px)').matches
        ? 12
        : 11
      const map = L.map('map', {
        zoom: ZOOM,
        minZoom: ZOOM - 2
      })
        .setView([52.022352455171124, 8.54414999485016], ZOOM)
        .setMaxBounds(
          L.latLngBounds(L.latLng(52.1744, 8.70363), L.latLng(51.8551, 8.33595))
        )
      map.attributionControl.setPrefix(false)
      map.attributionControl.setPosition('topright')
      map.zoomControl.setPosition('topright')

      const KEYS = ['basis', 'bezirke']
      // this.museum
      //   ? ['basis', 'bezirke2']
      //   : this.forPhoto
      //   ? ['basis', 'bezirke2']
      //   : ['bezirke']
      KEYS.forEach((key) => {
        L.tileLayer
          .wms(LAYERS[key].wms, {
            layers: LAYERS[key].layers,
            format: 'image/png',
            transparent: true,
            detectRetina: true,
            attribution: this.$t('map.attribution')
          })
          .addTo(map)
      })
      map
      map.on('click', (e) => {
        this.$store.dispatch('setLatLng', e.latlng)
        this.$store.commit('sideContent', false)
      })

      if (this.museum) {
        L.marker([52.0224, 8.5442], {
          icon: L.divIcon({
            className: 'icon-museum',
            iconSize: [50, 25]
          })
        }).addTo(map)
      }

      this.map = map
    }
  },
  mounted() {
    this.initMap()
    // this.forPhoto && this.$store.dispatch('setPosition')
  }
}
</script>

<style>
.biemap {
  height: 100%;
}
#map {
  height: 100%;
  background: #fff;
}
.map-medium.map-medium.map-medium img {
  display: block;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  border: 1.5px solid #fff;
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.9);
  border-radius: 20%;
  opacity: 0.5;
}
.map-medium.map-medium.map-medium img:hover {
  opacity: 1;
}
.icon-position {
  background: url('@/assets/position.svg') no-repeat;
  background-size: contain;
}
.icon-location {
  background: url('@/assets/pin.svg') no-repeat;
  background-size: contain;
}

.icon-museum {
  background: rgba(255, 255, 255, 0.8) url('@/assets/hist.svg') no-repeat center;
  background-size: 40px 20px;
  border-radius: 4px;
}
</style>
