<template>
  <div
    class="bieplan"
    :class="{ 'bieplan-museum': museum, 'plan-animated': animated }"
  >
    <div ref="panzoom" class="bieplan-img"></div>
    <DistanceSelect v-if="onMuseum" />
    <nav v-else class="plan-nav">
      <button
        @click="zoomIn"
        :disabled="zoom > 2.2"
        v-tippy="$t('plan.zoom.out')"
        class="button-icon button-round button-secondary"
        type="button"
      >
        <svg>
          <use href="@/assets/symbols.svg#zoomin" />
        </svg>
      </button>
      <button
        @click="zoomOut"
        :disabled="zoom < 1.1"
        v-tippy="$t('plan.zoom.in')"
        class="button-icon button-round button-secondary"
        type="button"
      >
        <svg>
          <use href="@/assets/symbols.svg#zoomout" />
        </svg>
      </button>
      <button
        @click="zoomAll"
        :disabled="zoom < 1.1"
        v-tippy="$t('plan.zoom.max')"
        class="button-icon button-round button-secondary"
        type="button"
      >
        <svg>
          <use href="@/assets/symbols.svg#crosshair" />
        </svg>
      </button>
    </nav>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import axios from 'axios'
import { initPanZoom } from '@/util'

import DistanceSelect from '@/components/DistanceSelect.vue'

export default {
  name: 'BiePlan',
  components: {
    DistanceSelect
  },
  data() {
    return {
      DISTRICTS: {
        brackwede: 82,
        dornberg: 68,
        gadderbaum: 90,
        heepen: 92,
        joellenbeck: 71,
        mitte: 99,
        schildesche: 70,
        senne: 93,
        sennestadt: 78,
        stieghorst: 86,

        // attractions:
        fernsehturm: 96,
        tierpark: 97,
        kunsthalle: 98,
        altermarkt: 99,
        schuecoarena: 100,
        sparrenburg: 102,
        viadukt: 101
      },
      ANIMATE_TIME: 300,
      TIMER: null,
      animated: false,
      zoom: 1,
      panZoom: null,
      svgPlaces: {}
    }
  },
  computed: Object.assign(
    mapState({
      ...mapGetters(['getPlaceById']),
      museum: (state) => state.museum,
      position: (state) => state.position
    }),
    {
      forPhoto() {
        return this.$route.name === 'photo'
      },
      onMuseum() {
        return this.museum || this.$route.name === 'museum'
      }
    }
  ),
  watch: {
    position(position) {
      if (this.$route.name === 'latlng') {
        this.$store.dispatch('setLatLng', position)
        this.$store.commit('sideContent', false)
      }
    }
  },
  methods: {
    _stopAnimate() {
      clearTimeout(this.TIMER)
      this.TIMER = setTimeout(() => {
        this.animated = false
        this.zoom = this.panZoom.getZoom()
      }, this.ANIMATE_TIME)
    },
    zoomIn() {
      this.animated = true
      this.panZoom.zoomBy(1.5)
      this._stopAnimate()
    },
    zoomOut() {
      this.animated = true
      this.panZoom.zoomBy(0.66666666)
      this._stopAnimate()
    },
    zoomAll() {
      this.animated = true
      this.panZoom.resize()
      this.panZoom.fit()
      this.panZoom.center()
      this._stopAnimate()
    },
    select(district) {
      const place = this.getPlaceById(this.DISTRICTS[district])
      this.$store.dispatch('setLatLng', place.latlng)
      this.$store.commit('sideContent', false)
      // this.$store.dispatch('selectPlace', id)
    }
  },
  mounted() {
    if (!this.panZoom) {
      axios
        .get('/stadtkarte.svg')
        .then((response) => {
          const $panZoom = this.$refs.panzoom
          $panZoom.innerHTML = response.data

          Object.keys(this.DISTRICTS).forEach((district) => {
            let $item =
              document.getElementById('s_' + district) ||
              document.getElementById('a_' + district)
            if ($item) {
              $item.addEventListener('click', (e) => {
                this.select(district)
              })
            } else {
              console.log(district, 'NOT FOUND')
            }
          })
          this.panZoom = initPanZoom($panZoom, {
            checkTarget: ($el) => {
              const id = $el.id
              if (id && (id?.startsWith('s_') || id?.startsWith('a_'))) {
                const district = id.substr(2)
                if (district) {
                  this.select(parseInt(district))
                  return true
                }
              }
              return false
            }
          })
        })
        .catch((e) => console.log(e))
    }
  }
}
</script>

<style>
.bieplan {
  position: relative;
  height: 100%;
}
.bieplan-img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  display: flex;
  justify-content: center;
}
.bieplan-img svg {
  display: block;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}
.bieplan-img g[id*='s_'] {
  stroke-width: 5px;
}
.bieplan-img g[id*='s_']:hover > path {
  stroke: var(--color1);
  fill: #fff;
}
.bieplan-img g[id*='s_']:hover > g * {
  fill: var(--color1);
}
.bieplan-img g[id*='a_']:hover > g * {
  stroke: var(--color1);
  fill: #fff;
}
</style>
