import { mapState } from 'vuex'
import axios from 'axios'
import { debounce, initPanZoom } from '@/util'

import PlacesSelect from '@/components/PlacesSelect.vue'

export default {
  name: 'Plan',
  components: {
    PlacesSelect
  },
  computed: mapState(['location', 'places', 'place', 'museum']),
  data() {
    return {
      ANIMATE_TIME: 300,
      TIMER: null,
      animated: false,
      panZoom: null,
      zoom: 1,
      svgPlaces: {},
      resizeListener: () => {}
    }
  },
  methods: {
    _stopAnimate() {
      clearTimeout(this.TIMER)
      this.TIMER = setTimeout(() => {
        this.animated = false
        this.zoom = this.panZoom.getZoom()
      }, this.ANIMATE_TIME)
    },
    panTo(place) {
      let $place = this.svgPlaces[place?.id]
      if (place && $place) {
        let sizes = this.panZoom.getSizes()
        let pan = this.panZoom.getPan()
        let r = $place.getBoundingClientRect()
        let rcx = r.left + r.width / 2
        let rcy = r.top + r.height / 2
        this.animated = true
        this.panZoom.pan({
          x: sizes.width / 2 - (rcx - pan.x),
          y: sizes.height / 2 - (rcy - pan.y)
        })
        this._stopAnimate()
      }
    },
    zoomIn() {
      this.animated = true
      this.panZoom.zoomBy(1.5)
      this._stopAnimate()
    },
    zoomOut() {
      this.animated = true
      this.panZoom.zoomBy(0.66666666)
      this._stopAnimate()
    },
    zoomAll() {
      this.animated = true
      this.panZoom.resize()
      this.panZoom.fit()
      this.panZoom.center()
      this._stopAnimate()
    },
    select(place) {
      this.$store.dispatch('selectPlace', place.id)
      this.$store.commit('sideContent', false)
    },
    init() {
      const $activePlace = this.$refs.panzoom.querySelector('.svg-selected')
      if ($activePlace) {
        $activePlace.classList.remove('svg-selected')
      }
      const place = this.place || this.location?.place
      if (place) {
        this.svgPlaces[place.id]?.classList.add('svg-selected')
      }
    }
  },
  mounted() {
    if (!this.panZoom) {
      axios
        .get('/plan.svg')
        .then((response) => {
          let $panZoom = this.$refs.panzoom
          $panZoom.innerHTML = response.data
          this.places.forEach((place) => {
            let $place = document.getElementById('place-' + place.id)
            if ($place) {
              $place.classList.add('svg-selectable')
              this.svgPlaces[place.id] = $place
              $place.addEventListener('click', (e) => {
                this.select(place)
              })
            }
          })
          const placeIds = this.places.map((place) => place.id)
          this.$nextTick(() => {
            this.panZoom = initPanZoom($panZoom, {
              checkTarget: ($target) => {
                if ($target.classList.contains('svg-selectable')) {
                  let id = parseInt($target.id.replace('place-', ''))
                  if (placeIds.includes(id)) {
                    this.select(this.places.find((place) => place?.id === id))
                    return true
                  }
                }
                return false
              }
            })
            this.zoomAll()
          })
        })
        .catch((e) => console.log(e))

      this.resizeListener = debounce(() => {
        this.panZoom && this.zoomAll()
      })
      window.addEventListener('resize', this.resizeListener)
    } else {
      this.init()
    }
  },
  activated() {
    this.init()
  },
  unmounted() {
    window.removeEventListener('resize', this.resizeListener)
  }
}
