<template>
  <div class="home">
    <section class="content">
      <MediaHeadList
        :media="userUploads"
        type="userUploads"
        area="all"
        details="latest"
      />
      <MediaHeadList
        :media="defaultUploads"
        type="defaultUploads"
        area="all"
        details="latest"
      />
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import util from '@/util'

import MediaHeadList from '@/components/MediaHeadList.vue'

export default {
  name: 'Home',
  components: {
    MediaHeadList
  },
  data() {
    return {
      loaded: false
    }
  },
  computed: mapState(['defaultUploads', 'userUploads']),
  mounted() {
    setTimeout(() => {
      this.loaded = true
    }, 1000)
  },
  activated() {
    this.$store.commit('title', this.$t('title'))
    this.$store.commit('sitetitle', '')
    this.$store
      .dispatch('loadMedia')
      .then(() => {})
      .catch((e) => util.notifyErrors(e))
  }
}
</script>

<style scoped>
.slide {
  transition: 2s ease-in-out;
  overflow: hidden;
  max-height: 20em;
}
.slide-enter,
.slide-leave-active {
  max-height: 0;
}
</style>
