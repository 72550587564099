<template>
  <h2 v-if="type" class="pagemargin more">
    <router-link
      v-if="linkmain && !media.empty"
      :to="linkmain"
      class="textcolor"
    >
      {{ $t('media.' + type, { area: '' }) }}
    </router-link>
    <router-link
      v-if="(linkall || linkmain) && !media.empty"
      :to="linkall || linkmain"
      class="textcolor"
    >
      <svg class="text-icon linkcolor">
        <use v-if="ui !== 'text'" href="@/assets/symbols.svg#chevron-right" />
        <use v-if="ui === 'text'" href="@/assets/symbols.svg#grid" />
      </svg>
      <small class="hide-s buttontext buttontext-normal space05before"
        >{{ $t('media.all') }}
      </small>
      <svg v-if="ui === 'text'" class="text-icon">
        <use href="@/assets/symbols.svg#chevron-right" />
      </svg>
    </router-link>
    <span v-if="!linkmain || media.empty">
      {{ $t('media.' + type, { area: '' }) }}
    </span>
  </h2>
</template>

<script>
export default {
  name: 'MediaHead',
  // media: pagedMedia
  // type: usedUploads, defaultUploads
  // ui: text
  props: ['media', 'type', 'ui', 'linkmain', 'linkall']
}
</script>
