<template>
  <span v-if="place">
    <img
      v-if="place.lines?.length"
      class="stop"
      src="@/assets/bahn.svg"
      alt=""
    />
    {{ place.title }}
  </span>
</template>

<script>
export default {
  name: 'Stop',
  props: ['place']
}
</script>

<style scoped>
b {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.stop {
  width: 1em;
  height: 1em;
  margin-right: 0.1em;
  vertical-align: -0.1em;
}
h1 .stop {
  width: 0.8em;
  height: 0.8em;
  vertical-align: -0.05em;
}
button .stop {
  vertical-align: -0.125em;
}
</style>
