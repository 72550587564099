<template>
  <MediaHead
    :media="media"
    :type="type"
    ui="text"
    :linkmain="{
      name: 'mediadetails',
      params: { area: area, type: type }
    }"
    :linkall="{
      name: 'media',
      params: { area: area, id: id, type: type }
    }"
  />
  <MediaList
    :media="media"
    :type="type"
    :details="details"
    :ui="ui || 'list'"
    :kind="kind"
    :linkmain="{
      name: 'mediadetails',
      params: { area: area, type: type }
    }"
  />
</template>

<script>
import MediaHead from '@/components/MediaHead.vue'
import MediaList from '@/components/MediaList.vue'

export default {
  name: 'MediaHeadList',
  components: {
    MediaHead,
    MediaList
  },
  props: ['media', 'type', 'details', 'ui', 'area', 'id', 'kind']
}
</script>
