import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import util from '@/util'
import Home from '../views/Home.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  // history: createWebHashHistory(),
  scrollBehavior() {
    // to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      name: 'home',
      path: '/',
      component: Home
    },
    {
      name: 'intro',
      path: '/blick',
      component: () => import('../views/intro/index.vue')
    },
    // {
    //   name: 'news',
    //   path: '/aktuelles',
    //   component: () => import('../views/news/index.vue')
    // },
    {
      name: 'partner',
      path: '/partner',
      component: () => import('../views/partner/index.vue')
    },
    {
      name: 'museum',
      path: '/museum',
      // meta: {
      //   needsUser: true
      // },
      component: () => import('../views/museum/index.vue')
    },
    {
      name: 'line',
      path: '/linie/:id',
      props: (route) => ({
        line: store.getters.getLineById(route.params.id)
      }),
      component: () => import('../views/line/index.vue')
    },
    {
      name: 'author',
      path: '/autor/:id',
      props: (route) => ({
        author: store.getters.getAuthorById(route.params.id)
      }),
      component: () => import('../views/author/index.vue')
    },
    {
      name: 'place',
      path: '/station/:id',
      props: (route) => ({
        place: store.getters.getPlaceById(route.params.id)
      }),
      component: () => import('../views/place/index.vue')
    },
    {
      name: 'latlng',
      path: '/standort/:latlng',
      props: (route) => {
        let [lat, lng] = route.params.latlng.split(',')
        return {
          latlng: [parseFloat(lat), parseFloat(lng)]
        }
      },
      component: () => import('../views/latlng/index.vue')
    },
    {
      name: 'mediadetails',
      // area: user|author|ort|place, type: defaultUploads|DEFAULT:userUploads
      path: '/details/:area?/:id?/:type?/m/:mediumid?',
      props: (route) => {
        return {
          area: route.params.area,
          id: route.params.id,
          type: route.params.type,
          mediumid: route.params.mediumid,
          ui: 'full'
        }
      },
      component: () => import('../views/media/index.vue')
    },
    {
      name: 'media',
      path: '/medium/:area?/:id?/:type?',
      props: (route) => {
        return {
          area: route.params.area,
          id: route.params.id,
          type: route.params.type,
          ui: 'grid'
        }
      },
      component: () => import('../views/media/index.vue')
    },
    {
      name: 'photo',
      path: '/foto',
      meta: {
        needsUser: true
      },
      component: () => import('../views/photo/index.vue')
    },
    {
      name: 'user',
      path: '/benutzer',
      meta: {
        needsUser: true
      },
      component: () => import('../views/user/index.vue')
    },
    {
      name: 'register',
      path: '/registrieren',
      component: () => import('../views/register/index.vue')
    },
    {
      name: 'login',
      path: '/login/:toname?',
      props: true,
      component: () => import('../views/login/index.vue')
    },
    {
      name: 'resetpassword',
      path: '/passwortvergessen',
      component: () => import('../views/resetpassword/index.vue')
    },
    {
      name: 'change',
      path: '/aendern',
      component: () => import('../views/change/index.vue')
    }
  ]
})

router.beforeEach((to, from, next) => {
  const needsUser = to.matched.some((record) => record.meta.needsUser)

  document.activeElement?.blur()

  if (!store.state.initialized) {
    store
      .dispatch('loadDashboard')
      .then(() => store.dispatch('initialize'))
      .then(() => {
        next({
          name:
            store.state.showIntro && to.name !== 'museum'
              ? 'intro'
              : to.name || 'home',
          params: to.params,
          query: to.query
        })
      })
      .catch((e) => {
        store.state.DEBUG && console.log('router.initialize', e)
        util.notifyErrors(e)
        if (to.name !== 'home') {
          next({ name: 'home' })
        }
      })
  } else {
    store.commit('more', false)
    store.commit('title', null)
    store.commit('title2', null)
    if (store.state.museum && to.name !== 'museum') {
      next({ name: 'museum' })
    } else if (needsUser && !store.state.user) {
      next({ name: 'login', params: { toname: to.name } })
    } else {
      next()
    }
  }
})

export default router
