import { toRaw } from 'vue'
import service from '@/service'
import store from '@/store'
import geolocation from '@/geolocation'

function parseISO(s) {
  if (s && typeof s === 'string') {
    let b = s.split(/\D/)
    return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5])
  } else {
    return s > 0 ? s : false
  }
}

const PAGESIZE_DEFAULT = 12
class Base {
  constructor(fields, options = {}) {
    options = Object.assign(
      {
        dateFields: [],
        arrays: [],
        idArrays: []
      },
      options
    )
    this.FIELDS = fields
    this.DATE_FIELDS = options.dateFields
    this.ARRAYS = options.arrays
    this.ID_ARRAYS = options.idArrays
    this.ARRAYS.forEach((a) => (this[a] = []))
  }
  update(init) {
    // convert to JS
    this.DATE_FIELDS.forEach((df) => {
      init[df] = parseISO(init[df]).valueOf()
    })
    this.FIELDS.forEach((field) => (this[field] = init[field]))
  }
  json() {
    let json = {}
    this.FIELDS.forEach((field) => {
      json[field] = this[field]
    })
    this.ARRAYS.forEach((a) => {
      json[a] = Object.values(this[a]).map((item) => {
        if (this.ID_ARRAYS.includes(a)) {
          return { id: item.id }
        } else {
          return item.json ? item.json() : item
        }
      })
    })
    return json
  }
}

class PagedMedia extends Base {
  constructor(init = {}, _filter = {}) {
    // config: path, filter for service updates
    super(['currentPage', 'hasMorePages', 'pageSize'], {
      arrays: ['media']
    })
    this._all = []
    this._filter = _filter
    this.media = []
    this.update(init)

    Object.defineProperty(this, 'empty', {
      get() {
        return this._all.length === 0
      }
    })
    Object.defineProperty(this, 'previews', {
      get() {
        return this._all.slice(0, PAGESIZE_DEFAULT)
      }
    })
  }
  update(init) {
    if (!init) {
      init = {}
    }
    if (!init.currentPage) {
      init.currentPage = 1
    }
    super.update(init)
    if (init.media) {
      let ids = this._all.map((medium) => medium.id)
      init.media.forEach((medium) => {
        if (!ids.includes(medium.id)) {
          // append
          this._all.push(new Medium(medium))
        } else {
          // update
          this._all[this._all.findIndex((a) => a.id === medium.id)] =
            new Medium(medium)
        }
        if (!init.NO_SORTING) {
          // latest are not sorted!
          this._all.sort((a, b) => {
            if (a.ts === b.ts) {
              return 0
            }
            return a.ts > b.ts ? -1 : 1
          })
        }
      })
      this.media = this._all.slice(0, this.currentPage * this.pageSize)
    }
  }
  load() {
    store.commit('working', true)
    return service
      .loadMedia(this.currentPage, this._filter)
      .then((init) => {
        store.commit('working', false)
        this.update(init)
        return Promise.resolve()
      })
      .catch((e) => {
        store.commit('working', false)
        return Promise.reject(e)
      })
  }
  start() {
    this.currentPage = 1
    this._all.length = 0
    return this.load()
  }
  prev() {
    if (this.currentPage > 1) {
      this.currentPage -= 1
      return this.load()
    }
    return Promise.resolve()
  }
  next() {
    if (this.hasMorePages) {
      this.currentPage += 1
      return this.load()
    }
    return Promise.resolve()
  }
  json() {
    let json = super.json()
    json._filter = toRaw(this._filter)
    json.media = Object.values(this._all)?.map((medium) => medium?.json())
    return json
  }
}

class Medium extends Base {
  constructor(init) {
    super(
      [
        'author',
        'description',
        'fileExtension',
        'id',
        'imageSize',
        'imgURL',
        'lat',
        'lng',
        'mediaDate',
        'place',
        'publishedAt',
        'rating',
        'street',
        'thumbURL',
        'title',
        'uploadType'
      ],
      {
        dateFields: ['publishedAt', 'mediaDate']
      }
    )
    this.update(init)

    store.commit('addAuthor', init.author)

    Object.defineProperty(this, 'isVideo', {
      get() {
        return ['mp4'].includes(this.fileExtension)
      }
    })
    Object.defineProperty(this, 'ts', {
      get() {
        return this.mediaDate || this.publishedAt
      }
    })
    Object.defineProperty(this, 'isUserUpload', {
      get() {
        return this.uploadType === 'userUpload'
      }
    })
    Object.defineProperty(this, 'authorName', {
      get() {
        return this.author?.fullName || ''
      }
    })
    Object.defineProperty(this, 'authorId', {
      get() {
        return this.author?.id
      }
    })
    Object.defineProperty(this, 'latlng', {
      get() {
        return this.lat && this.lng
          ? {
              lat: this.lat,
              lng: this.lng
            }
          : this.place
          ? store.getters.getPlaceById(this.place.id)?.latlng
          : {}
      }
    })
    Object.defineProperty(this, 'latlngShort', {
      get() {
        return this.lat
          ? [this.lat.toFixed(4), this.lng.toFixed(4)].join(', ')
          : ''
      }
    })
    Object.defineProperty(this, 'placeId', {
      get() {
        return this.place?.id
      }
    })
  }
  json() {
    let json = super.json()
    json.imageSize = toRaw(json.imageSize)
    json.author = toRaw(json.author)
    json.place = toRaw(json.place)
    return json
  }
}
Medium.prototype.addRating = function (rating) {
  return service
    .addRating(this.id, rating)
    .then((r) => {
      this.rating = r.rating
      return Promise.resolve()
    })
    .catch((e) => Promise.reject(e))
}

// class News extends Base {
//   constructor(init) {
//     super(['id', 'title', 'description', 'previewImage', 'updatedAt'], {
//       dateFields: ['updatedAt']
//     })
//     this.update(init)
//   }
// }

class User extends Base {
  constructor(init) {
    super(['id', 'email', 'fullName', 'username'])
    this.media = new PagedMedia(
      { currentPage: 1 },
      {
        author: init.id
      }
    )
    this.update(init)
  }
  loadMedia() {
    return this.media.load()
  }
}

class Line extends Base {
  constructor(init) {
    super(['id', 'name'], { arrays: ['places'] })
    this.update(init)
    this.color = getComputedStyle(document.documentElement).getPropertyValue(
      `--lineb${init.id}`
    )
  }
}
Line.prototype.addPlace = function (place) {
  if (!this.places.find((p) => p.id === place.id)) {
    this.places.push(place)
  }
}
Line.prototype.sort = function () {
  let START = {
    1: 22, // Schildesche
    2: 56, // Altenhagen
    3: 39, // Babenhausen Süd,
    4: 63 // Lohmannshof
  }
  let sorted = []
  sorted.push(
    this.places.find((p) => p.id === START[this.id]) || this.places[0]
  )
  let places = this.places.filter((p) => p.id !== sorted[0].id)

  while (places.length) {
    let next = geolocation.findNearestPlace(
      {
        lat: sorted[sorted.length - 1].lat,
        lng: sorted[sorted.length - 1].lng
      },
      places.filter((place) => place.lines?.length),
      true
    )
    if (next) {
      sorted.push(next)
      places = places.filter((p) => p.id !== next.id)
    } else {
      break // error?!
    }
  }
  this.places = sorted
}

class Place extends Base {
  constructor(init) {
    super(
      [
        'id',
        'description',
        'lat',
        'lng',
        'previewImage',
        'title',
        'totalUserUploads',
        'totalDefaultUploads'
      ],
      {
        arrays: ['features', 'lines', 'media'],
        idArrays: ['features', 'lines']
      }
    )
    this.update(init)

    Object.defineProperty(this, 'latest', {
      get() {
        const userMedia = this.media.filter((medium) => medium.isUserUpload)
        const defaultMedia = this.media.filter((medium) => !medium.isUserUpload)
        let latest = []
        if (userMedia.length) {
          latest.push(userMedia[0])
        }
        if (defaultMedia.length) {
          latest.push(defaultMedia[0])
        }
        return new PagedMedia(
          {
            media: latest,
            NO_SORTING: true
          },
          {
            uploadType: 'defaultUpload',
            filter_lat: this.lat,
            filter_lng: this.lng,
            filter_distance: 500
          }
          // { place: this.id, uploadType: 'defaultUpload' }
        )
      }
    })
    // Object.defineProperty(this, 'defaultUploads', {
    //   get() {
    //     return new PagedMedia(
    //       {
    //         media: this.media.filter((medium) => !medium.isUserUpload)
    //       },
    //       {
    //         uploadType: 'defaultUpload',
    //         filter_lat: this.lat,
    //         filter_lng: this.lng,
    //         filter_distance: 50
    //       }
    //       // { place: this.id, uploadType: 'defaultUpload' }
    //     )
    //   }
    // })
    // Object.defineProperty(this, 'userUploads', {
    //   get() {
    //     return new PagedMedia(
    //       {
    //         media: this.media.filter((medium) => medium.isUserUpload)
    //       },
    //       {
    //         uploadType: 'userUpload',
    //         filter_lat: this.lat,
    //         filter_lng: this.lng,
    //         filter_distance: 50
    //       }
    //       // { place: this.id, uploadType: 'userUpload' }
    //     )
    //   }
    // })
    Object.defineProperty(this, 'latlng', {
      get() {
        return this.lat && this.lng
          ? {
              lat: this.lat,
              lng: this.lng
            }
          : null
      }
    })
    Object.defineProperty(this, 'singleLine', {
      // used for plan color
      get() {
        return this.lines.length === 1 ? this.lines[0].name : ''
      }
    })
  }
  update(init) {
    init.lat = parseFloat(init.lat)
    init.lng = parseFloat(init.lng)

    super.update(init)
    if (init.media) {
      this.media.length = 0
      init.media.forEach((init) => this.media.push(new Medium(init)))
    }
    if (init.features) {
      this.features = init.features.map((init) =>
        store.getters.getFeatureById(init.id)
      )
    }
    if (init.lines) {
      this.lines = init.lines.map((init) => store.getters.getLineById(init.id))
      this.lines.forEach((line) => line.addPlace(this))
    }
  }
  related() {
    let prev = []
    let next = []
    if (this.lines?.length) {
      this.lines.forEach((line) => {
        let index = line.places.findIndex((p) => p.id === this.id)
        if (
          index > 0 &&
          !prev.find((p) => p.id === line.places[index - 1].id)
        ) {
          prev.push(line.places[index - 1])
        }
        if (
          index < line.places.length - 1 &&
          !next.find((p) => p.id === line.places[index + 1].id)
        ) {
          next.push(line.places[index + 1])
        }
      })
    }
    return { prev, next }
  }
}

class Feature extends Base {
  constructor(init) {
    super(['id', 'title', 'description'])
    this.update(init)
  }
}

export default {
  PagedMedia,
  Medium,
  // News,
  User,
  Line,
  Place,
  Feature
}
