<template>
  <button
    v-if="!museum"
    @click.stop="setPlace"
    class="textbutton linkcolor"
    type="button"
  >
    <Stop :place="place" />
  </button>
</template>

<script>
import { mapState } from 'vuex'

import Stop from '@/components/Stop.vue'

export default {
  name: 'StopAction',
  components: {
    Stop
  },
  props: ['place'],
  computed: mapState({
    museum: (state) => state.museum,
    storePlace: (state) => state.place
  }),
  methods: {
    setPlace() {
      if (this.place?.id !== this.storePlace?.id) {
        this.$store.dispatch('selectPlace', this.place?.id)
      } else if (this.$route.name !== 'place' && !this.museum) {
        this.$router.push({
          name: 'place',
          params: {
            id: this.place?.id
          }
        })
      }
    }
  }
}
</script>
