import { createI18n } from 'vue-i18n'
import de from './locales/de.json'

const datetimeFormats = {
  de: {
    date: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
    dateshort: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    },
    datenoyear: {
      month: '2-digit',
      day: '2-digit'
    },
    timeshort: {
      hour: 'numeric',
      minute: 'numeric'
    },
    datelong: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      weekday: 'long'
    },
    full: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    }
  }
}

const i18n = createI18n({
  globalInjection: true,
  locale: import.meta.env.VITE_APP_I18N_LOCALE || 'de',
  fallbackLocale: import.meta.env.VITE_APP_I18N_FALLBACK_LOCALE || 'de',
  messages: {
    de
  },
  datetimeFormats
})

export default i18n
