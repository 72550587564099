<template>
  <section
    class="plan"
    :class="{
      'plan-museum': museum,
      'plan-animated': animated,
      'plan-1': place ? place.singleLine === '1' : false,
      'plan-2': place ? place.singleLine === '2' : false,
      'plan-3': place ? place.singleLine === '3' : false,
      'plan-4': place ? place.singleLine === '4' : false
    }"
  >
    <div class="plan-img" ref="panzoom"></div>
    <nav class="plan-nav" v-if="!museum">
      <button
        @click="zoomIn"
        :disabled="zoom > 2.2"
        v-tippy="$t('plan.zoom.out')"
        class="button-icon button-round button-secondary"
        type="button"
      >
        <svg>
          <use href="@/assets/symbols.svg#zoomin" />
        </svg>
      </button>
      <button
        @click="zoomOut"
        :disabled="zoom < 1.1"
        v-tippy="$t('plan.zoom.in')"
        class="button-icon button-round button-secondary"
        type="button"
      >
        <svg>
          <use href="@/assets/symbols.svg#zoomout" />
        </svg>
      </button>
      <button
        @click="zoomAll"
        :disabled="zoom < 1.1"
        v-tippy="$t('plan.zoom.max')"
        class="button-icon button-round button-secondary"
        type="button"
      >
        <svg>
          <use href="@/assets/symbols.svg#crosshair" />
        </svg>
      </button>
    </nav>
  </section>
</template>

<script src="./index.js"></script>

<style>
.plan {
  position: relative;
  height: 100%;
}
.plan-img {
  height: 100%;
}
.plan-img svg {
  display: block;
  width: 100%;
  height: 100%;
  max-width: none;
  margin: 0 auto;
}

.svg-selectable {
  cursor: pointer;
}

@keyframes active {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.9;
  }
}
.svg-selected {
  animation: active 1s linear alternate infinite;
}
.svg-selected path {
  opacity: 1;
}
.plan path.svg-selected,
.plan .svg-selected path {
  fill: #00000044;
}
#plan-1 .svg-selected path {
  fill: #009fe355;
}
#plan-2 .svg-selected path {
  fill: #00964055;
}
#plan-3 .svg-selected path {
  fill: #ffed0088;
}
#plan-4 .svg-selected path {
  fill: #e3061355;
}
</style>
