<template>
  <div class="app" :class="{ offline: !onLine }">
    <div class="app-layout">
      <header
        v-if="!showIntro"
        class="app-header"
        :class="{ 'app-header-over': !title }"
      >
        <button
          v-if="!museum && (showBack || sideContent)"
          @click="back"
          type="button"
          class="button-icon button-simple app-back"
          :aria-label="$t('nav.back')"
        >
          <svg>
            <use href="@/assets/symbols.svg#back" />
          </svg>
        </button>

        <h1 v-if="title && !sideContent" class="app-title">
          <img
            v-if="showLogo"
            @click="clickStart"
            width="200"
            height="100"
            class="app-logo"
            src="@/assets/logo.svg"
            alt="Bielefeld Blick"
          />
          <span v-else>
            {{ title }}
            <small v-if="title2">{{ title2 }}</small>
          </span>
        </h1>
        <span class="app-link">
          <button
            v-if="showMediaTypeToggle"
            @click="toggleMediaType"
            v-tippy="$t('nav.mediatype-' + mediaType)"
            class="mainnav-a nohyphens"
            type="button"
          >
            <svg class="text-icon">
              <use
                v-if="mediaType !== 'userUploads'"
                href="@/assets/symbols.svg#useruploads"
              />
              <use v-else href="@/assets/symbols.svg#defaultuploads" />
            </svg>
          </button>
          <router-link
            v-if="!museum && titlelink && !sideContent"
            :to="titlelinkdef"
            replace
            v-tippy="
              $t(
                'nav.' +
                  (titlelinkdef.name === 'mediadetails' ? 'grid' : 'list')
              )
            "
            class="mainnav-a spacebefore"
          >
            <svg class="text-icon">
              <use
                v-if="titlelinkdef.name === 'mediadetails'"
                href="@/assets/symbols.svg#list"
              />
              <use v-else href="@/assets/symbols.svg#grid" />
            </svg>
          </router-link>
        </span>
      </header>
      <main id="main">
        <router-view v-slot="{ Component }">
          <transition name="page">
            <keep-alive>
              <component :is="Component" />
            </keep-alive>
          </transition>
        </router-view>
      </main>
      <!-- Fix Museum browser -->
      <aside class="app-side" :class="{ 'app-side-visible': sideContent }">
        <Side />
      </aside>
      <nav v-if="showMenu" class="mainnav">
        <div class="mainnav-content">
          <button
            @click="toggleMore"
            type="button"
            class="mainnav-icon button-icon moretoggle"
          >
            <svg>
              <use href="@/assets/symbols.svg#menu" />
            </svg>
          </button>
          <nav class="mainnav-more" :class="{ 'mainnav-more-visible': more }">
            <a @click="toggleMore" :href="$t('nav.infohref')" target="_system">
              {{ $t('nav.info') }}
            </a>
            <a
              @click="toggleMore"
              :href="$t('nav.museumhref')"
              target="_system"
            >
              {{ $t('nav.museum') }}
            </a>
            <a
              @click="toggleMore"
              :href="$t('nav.contacthref')"
              target="_system"
            >
              {{ $t('nav.contact') }}
            </a>
            <a
              @click="toggleMore"
              :href="$t('nav.partnerhref')"
              target="_system"
            >
              {{ $t('nav.partner') }}
            </a>
            <a
              @click="toggleMore"
              :href="$t('nav.privacyhref')"
              target="_system"
            >
              {{ $t('nav.privacy') }}
            </a>
            <a
              @click="toggleMore"
              :href="$t('nav.imprinthref')"
              target="_system"
            >
              {{ $t('nav.imprint') }}
            </a>
            <hr v-if="user" />
            <button
              v-if="user"
              @click="logout"
              class="button-text"
              type="button"
            >
              <b>{{ $t('nav.logout', { name: user.username }) }}</b>
            </button>
            <!-- <router-link :to="{ name: 'intro' }">
              {{ $t('nav.intro') }}
            </router-link>
            <router-link :to="{ name: 'news' }">
              {{ $t('nav.news') }}
            </router-link> -->
            <small class="version">
              v{{ VERSION }}
              <span v-if="VERSION_APP">app{{ VERSION_APP }}</span></small
            >
          </nav>

          <!-- museum: -->
          <span v-if="museum" class="nav-museum">
            <button
              @click="back"
              :class="{ disabled: !(showBack || sideContent) }"
              class="button-icon button-simple button-menu-museum linkcolor"
              :aria-label="$t('nav.back')"
              type="button"
            >
              <svg>
                <use href="@/assets/symbols.svg#back" />
              </svg>
            </button>
            <button
              @click="clickStart"
              v-tippy="$t('nav.home-title')"
              class="mainnav-a nohyphens nav-museum-home"
              type="button"
              :disabled="!place && $route.name === 'home'"
            >
              {{ $t('nav.home´') }}
            </button>
            <router-link
              :to="titlelinkdef"
              v-tippy="$t('media.all')"
              :class="{ disabled: !(titlelink || sideContent) }"
              class="button-icon button-simple"
            >
              <svg class="linkcolor">
                <use href="@/assets/symbols.svg#grid" />
              </svg>
            </router-link>
          </span>
          <!-- app: -->
          <span v-if="!museum" class="mainnav-link">
            <button
              @click="clickStart"
              v-tippy="$t('nav.home-title')"
              class="mainnav-a nohyphens"
              type="button"
              :disabled="!place && $route.name === 'home'"
            >
              {{ $t('nav.home') }}
            </button>
          </span>
          <router-link
            v-if="!museum"
            class="button button-round button-primary button-icon mainnav-photo"
            :to="{ name: 'photo' }"
            v-tippy="$t('nav.photo')"
            :class="{
              active: $route.name === 'photo',
              disabled: !user && ['login', 'register'].includes($route.name)
            }"
          >
            <svg>
              <use href="@/assets/symbols.svg#camera" />
            </svg>
          </router-link>
          <span v-if="!museum" class="mainnav-link">
            <router-link
              class="mainnav-a"
              :to="{ name: user ? 'user' : 'login' }"
              v-tippy="$t('nav.' + (user ? 'user' : 'login') + '-title')"
              :class="{
                disabled: ['login', 'user'].includes($route.name)
              }"
            >
              {{ user ? user.username : $t('nav.login') }}
            </router-link>
          </span>
          <span class="sidetoggle">
            <Actions />
          </span>
          <div v-if="working" class="working">
            <span class="action-loading">&nbsp;</span>
          </div>
        </div>
        <!-- :class="{ 'newslink-visible': newsVisible }" -->
        <!-- <router-link
          v-if="shownews"
          :to="{ name: 'news' }"
          class="newslink button-icon"
        >
          <svg class="newslink-icon">
            <use href="@/assets/symbols.svg#news" />
          </svg>
          {{ $t('nav.news') }}
        </router-link> -->
      </nav>
    </div>
    <notifications position="bottom right" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import util from '@/util'

import Side from '@/components/Side.vue'
import Actions from '@/components/Actions.vue'

export default {
  name: 'App',
  components: {
    Actions,
    Side
  },
  computed: Object.assign(
    mapState({
      // ...mapGetters(['getLatestNewsUpdate']),
      VERSION: (state) => state.VERSION,
      VERSION_APP: (state) => state.VERSION_APP,
      showIntro: (state) => state.showIntro,
      onLine: (state) => state.onLine,
      working: (state) => state.working,
      // newsServer: (state) => state.newsServer,
      // shownews: (state) => state.shownews,
      // newsViewed: (state) => state.newsViewed,
      sitetitle: (state) => state.sitetitle,
      title: (state) => state.title,
      title2: (state) => state.title2,
      titlelink: (state) => state.titlelink,
      user: (state) => state.user,
      more: (state) => state.more,
      sideContent: (state) => state.sideContent,
      places: (state) => state.places,
      place: (state) => state.place,
      position: (state) => state.position,
      location: (state) => state.location,
      author: (state) => state.author,
      museum: (state) => state.museum
    }),
    {
      showLogo() {
        return ['home', 'login', 'register', 'photo'].includes(this.$route.name)
      },
      showBack() {
        return !['home', 'intro'].includes(this.$route.name)
      },
      showMediaTypeToggle() {
        return ['media', 'mediadetails'].includes(this.$route.name)
      },
      showMenu() {
        return !(
          this.showIntro ||
          this.museum ||
          ['photo', 'line'].includes(this.$route.name)
        )
      },
      titlelinkdef() {
        return this.titlelink
          ? {
              name: this.titlelink.name || 'media',
              params: {
                type: this.titlelink.type,
                area: this.titlelink.area,
                id: this.titlelink.id
              }
            }
          : {
              name: 'home'
            }
      }
      // newsVisible() {
      //   return (
      //     this.$route.name === 'home' &&
      //     this.newsViewed < this.getLatestNewsUpdate()
      //   )
      // }
    }
  ),
  data() {
    return {
      loaded: false,
      resizeListener: util.debounce(this.setInnerHeight),
      mediaType: 'userUploads'
    }
  },
  watch: {
    sitetitle(sitetitle) {
      let page =
        sitetitle +
        (this.title2 && !sitetitle.includes(this.title2)
          ? ' ' + this.title2
          : '')
      document.title = page + (page ? ' – ' : '') + this.$t('title')
    },
    place(place) {
      if (
        place &&
        (this.$route.name !== 'place' ||
          parseInt(this.$route.params?.id) !== place.id) &&
        !this.museum
        // &&
        // this.$route.name !== 'photo'
      ) {
        this.$store.commit('more', false)
        this.$store.commit('sideContent', false)
        this.$router.push({
          name: 'place',
          params: {
            id: place.id
          }
        })
      }
    },
    position(position) {
      if (
        !this.museum &&
        position &&
        !['place', 'photo'].includes(this.$route.name)
      ) {
        this.$store.commit('more', false)
        this.$store.commit('sideContent', false)
        this.$router.push({
          name: 'latlng',
          params: {
            latlng: util.shortLatLng(position).join(',')
          }
        })
      }
    },
    author(author) {
      if (
        author &&
        (this.$route.name !== 'author' ||
          this.$route.params?.id !== author.id) &&
        !this.museum
      ) {
        this.$router.push({
          name: 'author',
          params: {
            id: author.id
          }
        })
      }
    }
  },
  methods: {
    setInnerHeight() {
      let height = document.documentElement.clientHeight || window.innerHeight
      document.documentElement.style.setProperty('--height', `${height}px`)
    },
    clickStart() {
      if (this.$route.name !== 'home') {
        this.$store.commit('place', null)
        this.$router.replace({ name: 'home' })
      }
    },
    back() {
      if (this.sideContent) {
        this.$store.commit('sideContent', false)
      } else {
        this.$router.back()
      }
    },
    toggleMediaType() {
      const route = this.$route
      const newType =
        route.params?.type === 'userUploads' ? 'defaultUploads' : 'userUploads'
      let params = Object.assign({}, route.params)
      params.type = newType
      this.mediaType = newType
      this.$router.replace({
        name: route.name,
        params: params
      })
    },
    toggleMore() {
      this.$store.commit('more', !this.more)
    },
    // closeNews() {
    //   this.$store.commit('shownews', false)
    // },
    logout() {
      this.$store.dispatch('logout').finally(() => {
        if (this.$route.name !== 'home') {
          this.$router.replace({ name: 'home' })
        }
      })
    },
    enter() {
      this.setInnerHeight()
      window.removeEventListener('resize', this.resizeListener)
      window.addEventListener('resize', this.resizeListener)
      this.loaded = true
    },
    exit() {
      window.removeEventListener('resize', this.resizeListener)
    }
  },
  created() {
    document.addEventListener(
      'swUpdated',
      (e) => {
        if (util.getPlatform() === 'web') {
          this.$notify({
            title: this.$t('pwaupdated'),
            type: 'error'
          })
        }
      },
      { once: true }
    )
  },
  mounted() {
    this.enter()
  },
  activated() {
    this.enter()
  },
  deactivated() {
    this.exit()
  },
  unmounted() {
    this.exit()
  }
}
</script>

<style scoped>
.app-layout {
  opacity: 1;
  transition: 0.5s;
}
.app-layout-hidden {
  opacity: 0;
}

.app.offline {
  border-top: 1px solid red;
}

@keyframes squeeze {
  0% {
    transform: scaleX(0.1);
  }
  50% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0.1);
  }
}
.working {
  display: block;
  background-color: var(--color1);
  height: 2px;
  animation: squeeze 2s linear infinite;
  margin: 0;
}

.app-header {
  z-index: 300;
}

.app-logo {
  height: 100%;
}

.mainnav {
  flex: 0 0 3em;
  z-index: 100;
  position: relative;
  bottom: 0;
  width: 100%;
  padding: 0;
  padding: 0 env(safe-area-inset-right) env(safe-area-inset-bottom)
    env(safe-area-inset-left);
  box-shadow: var(--shadow);
}
.mainnav-content {
  z-index: 20;
  position: relative;
  background: #fff;
  padding: 0 7em;
  padding: 0 max(5.5em, 7vw);
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-weight: bold;
}
.mainnav button {
  font-weight: inherit;
}

.moretoggle,
.sidetoggle {
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}
.moretoggle {
  left: 0;
}
.sidetoggle {
  right: 0;
}

.mainnav-icon {
  flex-shrink: 0;
  width: 3em;
  padding: 0 0.5em;
  height: 100%;
  color: var(--color1);
  background: none;
}
.mainnav-icon:hover,
.mainnav-icon:focus {
  color: #000;
}
@media (min-width: 35em) {
  .mainnav-icon {
    width: 7vw;
  }
}

.mainnav a,
.mainnav-a {
  color: inherit;
  flex: 0 0 auto;
}
.mainnav a:hover,
.mainnav a:focus,
.mainnav-a:hover,
.mainnav-a:focus {
  color: var(--color1);
}
.mainnav-link {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.5em;
}
.mainnav-a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-transform: uppercase;
  font-size: 0.875em;
  letter-spacing: 0.05em;
  background: none;
  border: none;
  max-width: 24ch;
  white-space: nowrap;
}

.mainnav-a svg,
.mainnav-icon svg {
  display: block;
  width: 2em;
  height: 2em;
  margin: 0 auto;
}

a.mainnav-photo {
  color: #fff;
  transform: translateY(-20%);
  border-radius: 100%;
  flex: 0 0 auto;
}

.moretoggle-hidden {
  opacity: 0;
  pointer-events: none;
  transform: translateX(-100%);
}
.mainnav-more {
  position: absolute;
  bottom: 100%;
  left: 0;
  background: #fff;
  padding: 0.7em 0;
  box-shadow: var(--shadow);
  transition: 0.15s ease-in-out;
  transform: translateX(-100%);
}
.mainnav-more-visible {
  transform: translateX(0);
}
.mainnav-more > *:not(hr) {
  display: block;
  padding: 0.5rem 1rem;
}
.mainnav-more > hr {
  margin: 0.5em 0;
}
.mainnav-more .version {
  font-weight: normal;
  text-align: right;
}
.mainnav .working {
  width: 100%;
  z-index: 200;
  position: absolute;
  top: 0;
  margin: 0;
}
.mainnav-photo {
  font-size: 1.2em;
  position: relative;
  width: 2.5em;
  height: 2.5em;
  padding: 0.3em;
  border: 0.25em solid #fff;
  box-shadow: none;
}

.mainnav-photo.active {
  pointer-events: none;
  opacity: 0.5;
  filter: saturate(0);
}
.mainnav-photo::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 2em;
  height: 2em;
  box-shadow: 0 0.2em 0.2em rgba(0, 0, 0, 0.3);
  border-radius: inherit;
}

.app-side {
  z-index: 400;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  transform: translate(100%);
  transition: 0.2s ease-in-out;
  overflow: hidden;
}
.app-side-visible {
  transform: translateX(0);
}

/* .newslink {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 50%;
  padding: 0.3em 2em;
  transform: translateX(-50%);
  color: #000;
  background: #fff;
  box-shadow: var(--shadow);
  border-radius: var(--radius);
  display: flex;
  white-space: nowrap;
  align-items: center;
  font-weight: bold;
  opacity: 0;
  transition: 0.25s;
}
.newslink-visible {
  transition: 0.5s 0.75s;
  transform: translateX(-50%) translateY(-4.5em);
  opacity: 1;
}
.newslink-icon {
  color: var(--color1);
  margin-right: 1em;
}
.newslink-close {
  margin-left: 1em;
  width: 1.5em;
  height: 1.5em;
} */

.nav-museum {
  display: flex;
  align-items: center;
  padding-right: 1em;
}
.nav-museum-home {
  margin-left: 3em;
  margin-right: 4em;
}
</style>
