<template>
  <label for="distance-select" class="distance-select">
    <input
      id="distance-select"
      v-model="distance"
      type="range"
      min="50"
      max="3000"
      step="50"
    />
    <b>{{ filterDistance }}m</b>
  </label>
</template>

<script>
import { mapState } from 'vuex'
import util from '@/util'

export default {
  name: 'DistanceSelect',
  data() {
    return {
      setDistance: null
    }
  },
  computed: Object.assign(
    mapState({
      filterDistance: (state) => state.filterDistance
    }),
    {
      distance: {
        get() {
          return this.filterDistance
        },
        set(distance) {
          this.setDistance(distance)
        }
      }
    }
  ),
  watch: {
    filterDistance(filterDistance) {
      this.locationRadius?.setRadius(filterDistance)
    }
  },
  mounted() {
    this.setDistance = util.debounce((distance) => {
      this.$store.commit('filterDistance', distance)
    }, 500)
  }
}
</script>

<style>
.distance-select {
  display: flex;
  align-items: center;
}
.distance-select input {
  flex: 1 1 100%;
  max-width: 15em;
}
.distance-select b {
  padding-left: 1ch;
  white-space: nowrap;
}
</style>
