import { createApp } from 'vue'
import { Network } from '@capacitor/network'
import { App as CapacitorApp } from '@capacitor/app'
import i18n from '@/i18n.js'
import store from '@/store/index.js'
import router from '@/router/index.js'
import { debounce, getPlatform } from '@/util/index.js'
import Notifications from '@kyvg/vue3-notification'
import App from './App.vue'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
// **see index.html as not working with vite**
// import { defineCustomElements } from '@ionic/pwa-elements/loader'
import '@/assets/css/style.css'
import VueTippy from 'vue-tippy'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light-border.css'


Promise.allSettled =
  Promise.allSettled ||
  ((promises) =>
    Promise.all(
      promises.map((promise) =>
        promise
          .then((value) => ({
            status: 'fulfilled',
            value
          }))
          .catch((reason) => ({
            status: 'rejected',
            reason
          }))
      )
    ))

const app = createApp(App)

app
  .use(i18n)
  .use(router)
  .use(store)
  .use(Notifications)
  .use(
    VueTippy,
    // optional
    {
      directive: 'tippy', // => v-tippy
      component: 'tippy', // => <tippy/>
      componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
      defaultProps: {
        theme: 'light-border',
        placement: 'top',
        touch: ['hold', 500],
        allowHTML: true
      } 
    }
  )

app.component('flat-pickr', flatPickr)

router.isReady().then(() => {
  app.mount('body')
  // hack to enable deselection of select input
  if (document.body?.firstElementChild) {
    document.body.firstElementChild.tabIndex = 1
  }
})


const platform = getPlatform()
if (platform !== 'web') {
  CapacitorApp.getInfo()
    .then((info) => store.commit('VERSION_APP', `${platform} ${info}`))
    .catch((e) => console.log(e))
} else {
  import('virtual:pwa-register/vue').then(({ useRegisterSW }) => {
    useRegisterSW({
      onRegistered() {
        console.log('onRegistered')
      },
      onRegisterError(e) {
        console.log('onRegisterError', e)
      },
      onOfflineReady() {
        console.log('onOfflineReady')
      },
      onNeedRefresh() {
        console.log('onNeedRefresh')
        document.dispatchEvent(new CustomEvent('swUpdated', { detail: {} }))
      }
    })
  })
}

window.addEventListener('orientationchange', (e) => {
  // hack for ios
  try {
    document.activeElement?.blur()
  } catch (err) {
    // nothing
  }
})

Network.addListener(
  'networkStatusChange',
  debounce((status) => {
    store.state.DEBUG && console.log('Network status', status)
    store.commit('onLine', status?.connected ? true : false)
  }, 300)
)

// CapacitorApp.addListener(
//   'appStateChange',
//   debounce((e) => {
//     store.state.DEBUG && console.log('App.appStateChange', e)
//     store.commit('active', e.isActive)
//   }, 250)
// )

CapacitorApp.addListener('appUrlOpen', function (data) {
  const slug = data.url.split('.app').pop()
  if (slug) {
    router.push({
      path: slug
    })
  }
})

app.config.errorHandler = (e) => {
  console.log('app', e)
}

import L from 'leaflet'

// ################ HACK FOR LEAFLETJS ################
// https://github.com/Leaflet/Leaflet/issues/4453#issuecomment-1151893365
// // L.Popup.prototype._animateZoom = function (e) {
// //   if (!this._map) {
// //     return
// //   }
// //   var pos = this._map._latLngToNewLayerPoint(this._latlng, e.zoom, e.center),
// //     anchor = this._getAnchor()
// //   L.DomUtil.setPosition(this._container, pos.add(anchor))
// // }
L.Marker.prototype._animateZoom = function (opt) {
  if (!this._map) {
    return
  }
  var pos = this._map
    ._latLngToNewLayerPoint(this._latlng, opt.zoom, opt.center)
    .round()
  this._setPos(pos)
}
L.Tooltip.prototype._animateZoom = function (e) {
  if (!this._map) {
    return
  }
  var pos = this._map._latLngToNewLayerPoint(this._latlng, e.zoom, e.center)
  this._setPosition(pos)
}
L.Tooltip.prototype._updatePosition = function () {
  if (!this._map) {
    return
  }
  var pos = this._map.latLngToLayerPoint(this._latlng)
  this._setPosition(pos)
}
// ################ /HACK FOR LEAFLETJS ################