import { toRaw } from 'vue'
import localforage from 'localforage'
import store from '@/store'

localforage.config({
  driver: localforage.INDEXEDDB,
  name: 'histapp',
  version: 1,
  storeName: 'keyvaluepairs',
  description: 'Daten für Hist App'
})

// LOCAL STORE
function load(key, def) {
  return localforage
    .getItem(key)
    .then((value) => {
      if (value !== null || def !== undefined) {
        return Promise.resolve(value !== null ? value : def)
      }
      store.state.DEBUG && console.log('device.load no value/def: ' + key)
      // return no error as normally ignored
      return Promise.reject({ message: 'device.load no value/def: ' + key })
    })
    .catch((e) => Promise.reject(e))
}
function save(key, value) {
  try {
    return localforage.setItem(key, toRaw(value))
  } catch (e) {
    store.state.DEBUG && console.log('device.save', key, value, e)
    return Promise.reject(e)
  }
}
function remove(key) {
  return localforage
    .removeItem(key)
    .then(() => Promise.resolve())
    .catch((e) => {
      store.state.DEBUG && console.log('device.remove', key, e)
      return Promise.resolve()
    })
}

export default {
  save,
  load,
  remove
}
