<template>
  <span>
    <button
      v-if="sideContent === 'Plan' || sideContent === false"
      @click="showBiePlan"
      v-tippy="$t('nav.BiePlan')"
      :aria-label="$t('nav.BiePlan')"
      class="mainnav-icon button-icon linkcolor"
      type="button"
    >
      <svg>
        <use href="@/assets/symbols.svg#map" />
      </svg>
    </button>
    <button
      v-if="sideContent === 'BiePlan'"
      @click="showPlan"
      v-tippy="$t('nav.Plan')"
      :aria-label="$t('nav.Plan')"
      class="mainnav-icon button-icon linkcolor"
      type="button"
    >
      <svg>
        <use href="@/assets/symbols.svg#plan" />
      </svg>
    </button>
    <button
      v-if="sideContent"
      @click="close"
      class="button-close mainnav-icon button-icon linkcolor"
      type="button"
    >
      <svg>
        <use href="@/assets/symbols.svg#x" />
      </svg>
    </button>
  </span>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Actions',
  computed: mapState(['sideContent']),
  methods: {
    showPlan() {
      this.$store.commit('sideContent', 'Plan')
    },
    showBiePlan() {
      this.$store.commit('sideContent', 'BiePlan')
    },
    close() {
      this.$store.commit('sideContent', false)
    }
  }
}
</script>

<style scoped>
span {
  display: flex;
}
.button-close {
  margin-left: 1vw;
}
</style>
