<template>
  <b>
    <svg class="text-icon">
      <use href="@/assets/symbols.svg#pin" />
    </svg>
    {{ $t('photo.location', { latlng: latlng.join(', ') }) }}
  </b>
</template>

<script>
export default {
  name: 'LatLng',
  props: ['latlng']
}
</script>

<style scoped>
.text-icon {
  vertical-align: -0.2em;
}
</style>
