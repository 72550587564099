<template>
  <router-link
    @click.stop=""
    :to="{
      name: 'latlng',
      params: { latlng: shorts.join(',') }
    }"
  >
    <svg class="text-icon">
      <use href="@/assets/symbols.svg#pin" />
    </svg>
    <span v-if="medium?.street">{{ medium.street }}</span>
    <span v-else>{{ $t('photo.location', { latlng: shorts.join(', ') }) }}</span>
  </router-link>
</template>

<script>
import util from '@/util'

export default {
  name: 'LatLng',
  props: ['latlng', 'medium'],
  computed: {
    shorts() {
      return util.shortLatLng(this.medium)
    }
  }
}
</script>

<style scoped>
.text-icon {
  vertical-align: -0.2em;
  margin-right: 0.3ch;
}
</style>
