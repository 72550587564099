<template>
  <button
    @click="setLocation"
    :disabled="locating"
    v-tippy="$t('setLocation.title')"
    class="button-text setlocation"
    type="button"
  >
    <span
      class="button-icon linkcolor"
      :class="{
        'action-loading': locating
      }"
    >
      <svg>
        <use href="@/assets/symbols.svg#pin" />
      </svg>
    </span>
    <small class="hide-s space05before">{{ $t('setLocation.title') }}</small>
  </button>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SetLocation',
  props: ['position'],
  computed: mapState(['locating']),
  methods: {
    setLocation() {
      this.$notify({
        title: this.$t('setLocation.loading'),
        duration: -1
      })
      this.$store
        .dispatch(this.position ? 'setPosition' : 'setLocation')
        .then((place) => {
          this.$notify({
            clean: true
          })
          this.$notify({
            title: this.$t('setLocation.' + (place ? 'found' : 'located')),
            text: place ? place.title : '',
            type: 'success'
          })
          if (!this.position) {
            this.$store.dispatch('selectPlace', place.id)
          }
        })
        .catch((e) => {
          this.$notify({
            clean: true
          })
          this.$notify({ title: e, type: 'error', duration: -1 })
        })
    }
  }
}
</script>

<style scoped>
.setlocation {
  display: flex;
  align-items: center;
  margin-bottom: 0.25em;
}
@media (min-width: 40em) {
  .setlocation {
    transform: translateX(0.4em);
  }
}
.setlocation:hover,
.setlocation:focus {
  color: var(--color1);
}
.setlocation > small {
  text-transform: uppercase;
}
</style>
