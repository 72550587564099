<template>
  <section class="side">
    <h1 v-tippy="$t('info.' + sideContent)">
      <svg class="text-icon space05after">
        <use href="@/assets/symbols.svg#info" />
      </svg>
      <span>
        {{ $t('nav.' + sideContent) }}
      </span>
    </h1>
    <div class="side-plan">
      <keep-alive>
        <component :is="sideContent" />
      </keep-alive>
    </div>
    <div class="cols-s side-location">
      <SetLocation :position="doPosition" />
      <Actions />
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'

import Plan from '@/components/Plan/index.vue'
import BieMap from '@/components/BieMap.vue'
import BiePlan from '@/components/BiePlan.vue'
import SetLocation from '@/components/SetLocation.vue'
import Actions from '@/components/Actions.vue'

export default {
  name: 'Side',
  props: ['mode'],
  computed: Object.assign(mapState(['sideContent']), {
    doPosition() {
      return this.sideContent !== 'Plan'
    }
  }),
  components: {
    Plan,
    BiePlan,
    BieMap,
    SetLocation,
    Actions
  }
}
</script>

<style scoped>
.side {
  position: relative;
  width: 100%;
  height: var(--height);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.side h1 {
  z-index: 20;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  max-width: calc(100% - 11em);
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.3em 0;
  margin: 0;
  font-size: 1em;
  white-space: nowrap;
}
@media (min-width: 40em) {
  .side h1 {
    padding: 0.6em 0;
  }
}
.side-plan {
  flex: 0 1 100%;
  width: 100%;
  z-index: 10;
  position: relative;
}
.side-location {
  z-index: 10;
  gap: 3vw;
  min-width: 3em;
  width: 100%;
  padding: 0;
  padding: 0 env(safe-area-inset-right) env(safe-area-inset-bottom)
    env(safe-area-inset-left);
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(3px);
  box-shadow: var(--shadow);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.side-location > * {
  margin: 0.25em 1vw;
}
.plan-places {
  flex: 1 1 auto;
  max-width: 25em;
}
.side-back {
  color: var(--color1);
}
</style>
